import { AppContextProvider } from '@lib/contexts/app.context';
import { AuthContextProvider } from '@lib/contexts/auth.context';
import { FeatureContextProvider } from '@lib/contexts/feature.context';
import * as Sentry from '@sentry/react';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { getApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import type { NextPage } from 'next';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import '../assets/fonts/Be-Vietnam-Pro/stylesheet.css';
import initAuth from '../initAuth';
import SEO from '../next-seo.config';
import '../styles/global.css';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (_page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: any;
};

initAuth();

const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

const BasketLiveApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? (page => page);

  useEffect(() => {
    TimeAgo.addDefaultLocale(en);
  }, []);

  useEffect(() => {
    const app = getApp();

    initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true,
    });
  }, []);

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, user => {
      if (user) {
        Sentry.setUser({ id: user.uid, email: user.email });
      } else {
        Sentry.setUser(null);
      }
    });
  }, []);

  const [queryClient] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <AppContextProvider>
          <FeatureContextProvider>
            <DefaultSeo {...SEO} />
            <Hydrate state={pageProps.dehydratedState}>
              {getLayout(<Component {...pageProps} />)}
            </Hydrate>
          </FeatureContextProvider>
        </AppContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
};

export default BasketLiveApp;
