import { Item } from '@lib/models/item.model';
import { ItemsAddedWithPercentages } from '@pages/stream.page';
import { useQueryClient } from '@tanstack/react-query';
import {
  collection,
  getCountFromServer,
  getFirestore,
  Unsubscribe,
} from 'firebase/firestore';
import { createContext, MutableRefObject, useRef, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';

export enum ItemType {
  PRODUCT = 'product',
  WEBPAGE = 'non_retail',
}

export enum ItemStatus {
  CHECKING = 'fixing',
  REPORTED = 'reported',
}

export type FilterFormType = {
  search?: string;
  type: Record<ItemType, boolean>;
  status?: ItemStatus;
};

const filterFormDefaultOptions = {
  search: null,
  type: {
    product: true,
    non_retail: true,
  },
  status: null,
};

export type AppContextType = {
  loading: boolean;
  setLoading: (_loading: boolean) => void;
  itemsCount: number;
  setItemsCount: (_count: number) => void;
  getCount: () => Promise<void>;
  items: Item[];
  setItems: (_items: any[]) => void;
  page: number;
  setPage: (_page: number) => void;
  size: number;
  setSize: (_size: number) => void;
  unsubscribeRef: MutableRefObject<Unsubscribe[]>;
  loadedItemsCount: number;
  setLoadedItemsCount: (_count: number) => void;
  setStatistics: (_stats: ItemsAddedWithPercentages) => void;
  statistics: ItemsAddedWithPercentages;
  filterForm: UseFormReturn<FilterFormType, any>;
  filterOptions: FilterFormType;
  setFilterOptions: (_options: FilterFormType) => void;
  onFilterFormSubmit: (_options: FilterFormType) => void;
};

const AppContext = createContext<AppContextType>({} as AppContextType);

const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { query } = useRouter();
  const searchQueryParam = query['search'];
  const statusQueryParam = query['status'];

  const defaultFilters = {
    ...filterFormDefaultOptions,
    ...(searchQueryParam && { search: searchQueryParam as string }),
    ...(statusQueryParam && { status: statusQueryParam as ItemStatus }),
  };

  const [filterOptions, setFilterOptions] =
    useState<FilterFormType>(defaultFilters);

  const [size, setSize] = useState(100);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadedItemsCount, setLoadedItemsCount] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);
  const [items, setItems] = useState([]);
  const [statistics, setStatistics] =
    useState<ItemsAddedWithPercentages | null>(null);
  const unsubscribeRef = useRef<Unsubscribe[]>([]);

  const getCount = async () => {
    const fireStore = getFirestore();
    const itemsDocRef = collection(fireStore, 'items');
    const itemsDocSnapshot = await getCountFromServer(itemsDocRef);
    setItemsCount(itemsDocSnapshot.data().count);
  };

  const filterForm = useForm<FilterFormType>({
    reValidateMode: 'onChange',
    defaultValues: defaultFilters,
  });

  const queryClient = useQueryClient();

  const onFilterFormSubmit = (values: FilterFormType) => {
    setFilterOptions(prev => {
      const isSame = isEqual(prev, values);
      if (!isSame) {
        setPage(1);
        queryClient.removeQueries();
      }

      return values;
    });

    setFilterOptions(values);
  };

  return (
    <AppContext.Provider
      value={{
        onFilterFormSubmit,
        filterForm,
        filterOptions,
        setFilterOptions,
        setStatistics,
        statistics,
        setLoadedItemsCount,
        loadedItemsCount,
        unsubscribeRef,
        size,
        setSize,
        page,
        setPage,
        items,
        setItems,
        itemsCount,
        setItemsCount,
        loading,
        setLoading,
        getCount,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContextProvider, AppContext };
