import { captureException } from '@sentry/nextjs';
import { init } from 'next-firebase-auth';

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY as string, // required
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DB_URL,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
};

const initAuth = () => {
  init({
    authPageURL: '/auth/signin',
    appPageURL: () => {
      if (typeof window === 'undefined') return '/';
      const params = new URLSearchParams(window.location.search);
      const destinationParamVal = params.get('destination')
        ? decodeURIComponent(params.get('destination'))
        : undefined;

      // By default, go to the index page if the destination URL
      // is invalid or unspecified.
      let destURL = '/callback';
      if (destinationParamVal) {
        // Verify the redirect URL host is allowed.
        // https://owasp.org/www-project-web-security-testing-guide/v41/4-Web_Application_Security_Testing/11-Client_Side_Testing/04-Testing_for_Client_Side_URL_Redirect
        const allowedHosts = ['localhost:3000', ''];
        const allowed =
          allowedHosts.indexOf(new URL(destinationParamVal).host) > -1;
        if (allowed) {
          destURL = destinationParamVal;
        } else {
          // eslint-disable-next-line no-console
          console.warn(
            `Redirect destination host must be one of ${allowedHosts.join(
              ', ',
            )}.`,
          );
        }
      }
      return destURL;
    },
    loginAPIEndpoint: '/api/login', // required
    logoutAPIEndpoint: '/api/logout', // required
    onLoginRequestError: err => {
      console.error(err);
    },
    onLogoutRequestError: err => {
      console.error(err);
    },
    firebaseAdminInitConfig: {
      credential: {
        projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
        clientEmail: process.env.FIREBASE_SA_CLIENT_EMAIL,
        privateKey: process.env.FIREBASE_PRIVATE_KEY
          ? process.env.FIREBASE_PRIVATE_KEY.replace(/\\n/g, '\n')
          : undefined,
      },
      databaseURL: process.env.FIREBASE_DB_URL,
    },
    // Use application default credentials (takes precedence over fireaseAdminInitConfig if set)
    useFirebaseAdminDefaultCredential: false,
    firebaseClientInitConfig: firebaseConfig,
    cookies: {
      name: 'Basket Live App', // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: '/',
      sameSite: 'Lax',
      secure: false, // set this to true in production node_envs
      signed: false, // turn back on once debugged,
    },
    onVerifyTokenError: err => {
      console.error(err);
      captureException(err);
    },
    onTokenRefreshError: err => {
      console.error(err);
      captureException(err);
    },
  } as any);
};

export default initAuth;
