import { Feature } from '@lib/constants/feature';
import { getFeatureArray } from '@lib/helpers/feature.helper';
import { createContext, useEffect, useState } from 'react';

export type FeatureContextType = {
  features: Feature[];
  hasFeature: (_feature: Feature) => boolean;
};

const FeatureContext = createContext<FeatureContextType>(
  {} as FeatureContextType,
);

const FeatureContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [features, setFeatures] = useState<Feature[]>([]);

  useEffect(() => {
    const items = getFeatureArray();
    setFeatures(items as Feature[]);
  }, []);

  const hasFeature = (feature: Feature) => features.includes(feature);

  return (
    <FeatureContext.Provider value={{ features, hasFeature }}>
      {children}
    </FeatureContext.Provider>
  );
};

export { FeatureContextProvider, FeatureContext };
