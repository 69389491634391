import { User } from 'next-firebase-auth';
import { useRouter } from 'next/router';
import { createContext } from 'react';

export type AuthContextType = {
  signOut: (_authUser: User) => Promise<void>;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();

  const signoutUser = async (authUser: User) => {
    await authUser?.signOut();

    router.push('/logout');
  };

  return (
    <AuthContext.Provider
      value={{
        signOut: signoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, AuthContext };
