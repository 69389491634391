const additionalMetaTags = [
  {
    property: 'theme-color',
    content: '#3b383f',
  },
  {
    property: 'application-name',
    content: 'Basket',
  },
  {
    property: 'msapplication-TileColor',
    content: '#f5354d',
  },
];

const additionalLinkTags = [
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/favicon/apple-touch-icon.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicon/favicon-32px.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '48x48',
    href: '/favicon/favicon-48px.png',
  },
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials',
  },
];

const SEO = {
  title: 'Basket - Shop Everything',
  description:
    'Make shopping effortless with a single basket for all the things you love.',
  openGraph: {
    title: 'Basket - Shop Everything',
    description:
      'Make shopping effortless with a single basket for all the things you love.',
    type: 'website',
    locale: 'en_GB',
    url: 'https://www.trybasket.com/',
    site_name: 'Basket',
    images: [
      {
        url: '/assets/OpenGraph.jpg',
        width: 1200,
        height: 630,
        alt: 'Basket OpenGraph',
      },
    ],
  },
  twitter: {
    handle: '@trybasket',
    site: '@site',
    cardType: 'https://www.trybasket.com/assets/OpenGraph.jpg',
  },
  facebook: {
    appId: process.env['NEXT_PUBLIC_FACEBOOK_ID'],
  },
  additionalLinkTags,
  additionalMetaTags,
};

export default SEO;
